body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  height: 100%;
  box-sizing: border-box;
  /* Include padding in width calculations */
}

html {
  overflow-x: hidden;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Responsive image styles */
img {
  max-width: 100%;
  height: auto;
}

.ocean {
  z-index: -1;
  width: 50px;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  background: #f09745;
}

.wave {
  background: url(/images/background/wave.png) repeat-y;
  position: absolute;
  right: 50px;
  height: 6400px;
  width: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  right: 27px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-top: 0;
  }

  100% {
    margin-top: -1600px;
  }
}

@keyframes swell {

  0%,
  100% {
    transform: translate3d(-25px, 0, 0);
  }

  50% {
    transform: translate3d(5px, 0, 0);
  }
}

/* Mui */
.MuiTabs-indicator {
  background-color: #f09745 !important;
}

/* Responsive utility classes */
.hide-on-mobile {
  display: block;
}

.show-on-mobile {
  display: none;
}

/* Media Queries */
@media (max-width: 600px) {
  .hide-on-mobile {
    display: none !important;
  }

  .show-on-mobile {
    display: block !important;
  }

  /* Make text smaller on mobile */
  h1,
  h2,
  h3 {
    word-break: break-word;
  }

  /* Adjust wave animation for mobile */
  .ocean {
    width: 30px;
  }

  .wave {
    right: 30px;
    width: 120px;
  }

  .wave:nth-of-type(2) {
    right: 17px;
  }

  /* Background optimizations for mobile */
  [style*="background-image"] {
    background-attachment: scroll !important;
    /* Force scrollable backgrounds on mobile */
    background-size: cover !important;
    background-position: center !important;
  }

  /* Fix for background images defined in stylesheets */
  [class*="container"] {
    background-size: cover !important;
    background-position: center !important;
  }

  /* Fix for iOS background-attachment issues */
  html {
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }

  body {
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
  }
}